import "core-js/modules/es6.number.constructor.js";
import { useMutation } from '@vue/apollo-composable';
import { SfdrStrategyKind, useAddSfdrStrategyMutation } from '~/types/types';
import updateFundMutation from '~/graphql/mutations/account/UpdateFund.gql';
import deleteSfdrStrategyMutation from '~/graphql/mutations/account/DeleteSfdrStrategy.gql';
export default defineComponent({
  props: {
    sustainableObjectives: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    fundId: {
      type: Number,
      required: true
    },
    fund: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var _useAddSfdrStrategyMu = useAddSfdrStrategyMutation(),
      addSfdrStrategy = _useAddSfdrStrategyMu.mutate;
    var _useMutation = useMutation(deleteSfdrStrategyMutation),
      deleteSfdrStrategy = _useMutation.mutate,
      deleteLoading = _useMutation.loading,
      onDone = _useMutation.onDone,
      onError = _useMutation.onError;
    var _useMutation2 = useMutation(updateFundMutation),
      updateFund = _useMutation2.mutate;
    var triggerUpdateFund = function triggerUpdateFund(key, val) {
      var input = {
        id: props.fundId
      };
      input[key] = val === undefined || val === '' ? null : val;
      updateFund({
        input: input
      });
    };
    var currentLoadingItemId = ref(null);
    onDone(function () {
      currentLoadingItemId.value = null;
      window.$nuxt.$emit('userError', {
        message: 'Sustainable objective was successfully deleted',
        color: 'green'
      });
    });
    onError(function () {
      window.$nuxt.$emit('userError', {
        message: 'An error occurred. Please try again'
      });
    });
    return {
      addSfdrStrategy: addSfdrStrategy,
      deleteSfdrStrategy: deleteSfdrStrategy,
      deleteLoading: deleteLoading,
      currentLoadingItemId: currentLoadingItemId,
      triggerUpdateFund: triggerUpdateFund
    };
  },
  data: function data() {
    return {};
  },
  methods: {
    triggerCreate: function triggerCreate() {
      this.addSfdrStrategy({
        input: {
          fundId: this.fundId,
          kind: SfdrStrategyKind.SustainableObjective
        }
      });
    },
    getItemStrategy: function getItemStrategy(item) {
      if (item.concept) return item.concept.label;
      return 'No sustainable objective defined';
    },
    handleDeleteSustainableObjective: function handleDeleteSustainableObjective(id, callback) {
      this.currentLoadingItemId = id;
      this.deleteSfdrStrategy({
        id: id
      });
      callback();
    }
  }
});